import script from "./GetTranscription.vue?vue&type=script&setup=true&lang=js"
export * from "./GetTranscription.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerBars from 'quasar/src/components/spinner/QSpinnerBars.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QSpinnerBars,QBanner,QCard,QCardSection});
